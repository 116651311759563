import Layout from '@/components/Layout';
import HeroImageLg from '@/public/assets/hero.png';
import HeroImageSm from '@/public/assets/hero.png';
import SwitchToProductionMode from '@/components/SwitchToProdMode';
import classes from './styles.module.scss';
import {
  Box,
  Image,
  Heading,
  Flex,
  Text,
  useMediaQuery,
  toast,
  ButtonGroup,
  Button,
} from '@chakra-ui/react';
import CTA from '@/components/UI/CTA';
import ToggleRow from '@/components/ToggleRow';
import SKCard from '@/components/UI/SKCard';
import { SIKKA_FAQS } from '@/data/faq';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import FAQLanguageTitle from '@/components/FAQLanguageTitle';

export default function HomePage(props) {
  const [lang, setLang] = useState('en'); //en||hi
  const router = useRouter();
  const [hash, setHash] = useState('');
  const asPath = router.asPath;
  useEffect(() => {
    const cur = asPath.split('#')[1];
    setHash(cur);
  }, [asPath]);
  return (
    <Layout>
      <Flex
        flexDir={['column', 'column', 'row']}
        justifyContent={'center'}
        alignItems={'center'}
        pr={[2, 0]}
        className={classes.hero}
        // mt={-20}
        // mb={-24}
      >
        <Flex
          flexDir={'column'}
          maxW={['100%']}
          pr={[0, 0, 0, 0, 8]}
          textAlign={['left']}
          mt={['20px', '50px']}
        >
          <Heading
            fontSize={['28px', '30px', '36px', '42px', '48px']}
            mb={['10px', '20px']}
          >
            The wait is over! Now, earn money{' '}
            <span className={classes.textBg}> superrrrfast</span> with India’s
            newest Earning App
          </Heading>
          <Box pr={6} w={['100%']} display={['block', null, 'none']}>
            <Image
              src={HeroImageSm.src}
              alt="MoneyHi Duo illustration"
              mt={[4, null, 'unset']}
              height={['45vh', '45vh', null, '50vh']}
              mx={'auto'}
            />
          </Box>
          <Text
            fontSize={['16px', '16px', '18px', '20px']}
            fontWeight={['400']}
            lineHeight={[6, null, 8]}
            mb={'30px'}
            opacity={0.6}
            color={'#1B1B1B'}
          >
            Earning money is never easy, it takes hard work and passion but how
            about we make it easy for you? Presenting you the newest application
            where you can earn money instantly.
          </Text>
          <Flex justifyContent={['center', 'flex-start']} px={[4, 0]}>
            <CTA />
          </Flex>
        </Flex>

        <Box display={['none', null, 'block']}>
          <Image
            src={HeroImageLg.src}
            alt="MoneyHi Duo illustration"
            width={['40vw']}
            maxWidth={['550px']}
          />
        </Box>
      </Flex>
      <Flex
        direction={'column'}
        gap={6}
        alignItems={['flex-start', null, 'center']}
        mt={['3rem']}
      >
        <FAQLanguageTitle lang={lang} />
        <div style={{ margin: '1rem 0rem' }}>
          <ButtonGroup variant="solid" spacing="0" size="xs" isAttached>
            <Button
              colorScheme={lang === 'en' ? 'brand' : 'gray'}
              onClick={() => setLang('en')}
            >
              English
            </Button>
            <Button
              colorScheme={lang === 'hi' ? 'brand' : 'gray'}
              onClick={() => setLang('hi')}
            >
              Hindi
            </Button>
            <Button
              colorScheme={lang === 'mr' ? 'brand' : 'gray'}
              onClick={() => setLang('mr')}
            >
              Marathi
            </Button>
            <Button
              colorScheme={lang === 'gu' ? 'brand' : 'gray'}
              onClick={() => setLang('gu')}
            >
              Gujarati
            </Button>
          </ButtonGroup>
        </div>
        <div className={classes.FaqList}>
          {SIKKA_FAQS.map(d => (
            <SKCard className={classes.card} id={`${d.key}`} key={d.key}>
              <ToggleRow
                key={d.key}
                header={
                  <div className={classes.rowHeader}>{d[lang].title}</div>
                }
                body={d[lang].desc}
                defaultOpen={`#${d.key}` === hash}
              />
            </SKCard>
          ))}
        </div>
      </Flex>
      <SwitchToProductionMode />
    </Layout>
  );
}
